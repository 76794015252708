<template>
    <zw-sidebar @shown="shown" :title="$t('mail.title.mail')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.subject" name="subject" readonly :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <label>{{ $t(labelPrefix + 'body') }}</label>
                        <div v-html="form.content" class="bg-white text-dark p-2 mb-2">
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <label>
                            {{ $t(labelPrefix + 'customer_id') }}
                        </label>
                        <div>
                            <a href="#"
                               v-if="form.project_mail.customer"
                               @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:form.project_mail.customer.id})">
                                {{ form.project_mail.customer.agenturname }}
                                {{ form.project_mail.customer.contact_person.vorname }}
                                {{ form.project_mail.customer.contact_person.name }}
                            </a>
                        </div>
                    </b-col>
                    <b-col cols="6">
                        <label>
                            {{ $t(labelPrefix + 'offering_id') }}
                        </label>
                        <div>
                            <a href="#"
                               v-if="form.project_mail.offering"
                               @click.prevent="$root.$children[0].openModal('offering-modal', {id:form.project_mail.offering.id})">
                                {{ form.project_mail.offering.number }}
                            </a>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="form.project_mail.attachments">
                    <b-col cols="12">
                        <label>
                            {{ $t(labelPrefix + 'attachments') }}
                        </label>
                        <div class="bg-white text-dark p-2 mb-2">
                            <div v-for="(atttachment,index) in form.project_mail.attachments" :key="index">
                                <a
                                    href="#"
                                    @click.prevent="openAttachment(atttachment)">
                                    <template v-if="atttachment.name">{{ atttachment.name.replace(/^.*[\\\/]/, '') }}</template>
                                    <template v-else>{{ atttachment }}</template>
                                </a>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <zw-input-group v-model="form.recipient_email" name="recipient_email" readonly
                                        :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.recipient_name" name="recipient_name" readonly
                                        :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <zw-input-group v-model="form.sender_email" name="sender_email" readonly
                                        :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.sender_name" name="sender_name" readonly
                                        :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <zw-input-group :value="form.created_at  | formatDate('DD.MM.YYYY HH:mm:ss')"
                                        name="created_at" readonly :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group :value="form.updated_at  | formatDate('DD.MM.YYYY HH:mm:ss')"
                                        name="updated_at" readonly :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <zw-input-group :value="form.clicked_at  | formatDate('DD.MM.YYYY HH:mm:ss')"
                                        name="clicked_at" readonly :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.clicks" name="clicks" readonly :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <zw-input-group :value="form.opened_at  | formatDate('DD.MM.YYYY HH:mm:ss')"
                                        name="opened_at" readonly :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.opens" name="opens" readonly :label-prefix="labelPrefix">
                        </zw-input-group>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'ShowEmailMessageModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: null,
            labelPrefix: 'mail.label.',
        }
    },
    methods: {
        ...mapGetters('Mail', ['getMailMessageData']),
        shown() {
            this.$store.dispatch('Mail/fetchMailMessageData', {
                id: this.payload.messageId,
            })
                .then(() => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(this.getMailMessageData()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        openAttachment(attachment) {
            if(attachment.type) {
                if (attachment.type == 'attachment' || attachment.type == 'offering_attachment') {
                    this.$root.openDocument(attachment.id, 'media')
                } else if (attachment.type == 'contract') {
                    this.$root.openDocument(attachment.name, 'customer_contracts')
                } else if (attachment.type == 'simple') {
                    this.$root.openDocument(attachment.name, 'simple')
                } else {
                    this.$root.openDocument(attachment.name, 'invoices')
                }
            } else {
                this.$root.openDocument(encodeURIComponent(attachment), 'absolute')
            }
        }
    }
}
</script>